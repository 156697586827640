import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (
      <div className="footer">
        <div>
          <p>Grafika: <a href="https://cheellart.hu/" target="_blank" rel="noopener noreferrer">cheellart.hu</a></p> 
          <p>Fejlesztő: Python Suli</p> 
        </div>

        <div>
          <p>mail.pythonsuli@gmail.com</p>
        </div>

        <div>
          <a href="https://pythonsuli.hu/static/Python_Suli_Adatkezelesi.pdf" target="_blank" rel="noopener noreferrer">Adatkezelési tájékoztató</a>
          <Link to="/impresszum">Impresszum</Link>
        </div>
      </div>
    );
  }
  
  export default Footer;