import React, {useEffect, useContext, Fragment}  from 'react'
import { useLocation } from 'react-router-dom'
import { CourseContext } from '../contexts/CourseContext'
import GroupCard from '../micro/GroupCard'
import HaladoKep from "../../images/full_stack.webp"
import { Link } from 'react-router-dom'
import {motion} from "framer-motion"

export default function PythonHalado() {
  const {group_list, course_data, set_course, set_course_data} = useContext(CourseContext)
  const location = useLocation()

  useEffect(() => {
    set_course(location.pathname.substring(1))
  }, [])

  if(!course_data) return

  return (
    <motion.div className='course-details-container' initial={{opacity:0}} animate={{opacity:1}}>
      <div className='course-header'>
        <img src={HaladoKep} alt="" />
        <div>
          <h1 className='title'>{course_data.name}</h1>
          <h3>{course_data.description}</h3>
          <h2>
            <ul>
              <li>Óraszám: <b>{course_data.hours}</b></li>
              <li>Részvételi díj: <b>{course_data.price}</b></li>
              <li>Oktatás jellege: <b>{course_data.type}</b></li>
              <li>Szint: <b>{course_data.level}</b></li>
              <li>Előképzettség: <b>A <Link to="/kezdo">Python Kezdő tanfolyam</Link> elvégzése, vagy legalább közép szintű Python ismeretek szükségesek.</b></li>
            </ul>
          </h2>
        </div>
      </div>

      {
        group_list.length === 0&& 
        <div className='no-groups-container'>
          <h1 className='title'>Erre a tanfolyamra elfogytak az időpontok :(</h1>
          <h1>Kövesd <a href="https://www.facebook.com/pythonsuli/" target="_blank" rel="noopener noreferrer">Facebook oldalamat</a> , vagy csatlakozz a <a href="https://discord.gg/GXqjUehbv7" target="_blank" rel="noopener noreferrer">Python Suli Discord</a> szerveréhez ahol időben értesülhetsz arról ha új tanfolyam indul.</h1>
        </div>
      }

      <div className='course-details'>
        <div className='modules-container'>
          <h2>

            <h2><b>Modul 1. - Desktop alkalmazás fejlesztés</b></h2>
            <small>Ablak alkalmazások (GUI applications) fejlesztése Qt környezetben</small><br /><br />
            <ul>
              <li>GIT alapok, avagy: verziókövetés</li>
              <li>A Qt környezet bemutatása</li>
              <li>QtWidgets vagy QtQuick?</li>
              <li>Egy egyszerű ablak elkészítése</li>
              <li>Widgetek és Layoutok bemutatása</li>
              <li>QtQuick alapok</li>
              <li>QML alapok</li>
              <li>Python integráció</li>
            </ul>
            <hr />
            <h2><b>Modul 2.</b></h2>            
            <ul>
              <li>Layout tervezés</li>
              <li>QML komponensek</li>
              <li>List View és Model használata</li>
              <li>Egyedi nézetek és a Delegate</li>
            </ul>
            <hr />
            <h2><b>Modul 3.</b></h2>            
            <ul>
              <li>Adatok letöltése a háttérben: QThread</li>
              <li>Egyedi signal/slot rendszer a háttérfolyamatoknak.</li>
              <li>Háttérfolyamatok megjelenítése: Progressbar</li>
              <li>Kereső, szűrő funkciók kialakítása</li>
              <li>Adatok rendezése és megjelenítése</li>
              <li>Projekt fordítása futtatható fájlba: Packaging</li>
            </ul>
            <hr />
            <h2><b>Modul 4. - Webfejlesztés</b></h2>
            <small>Modern webfejlesztés Django és React környezetben</small><br /><br />  
            <ul>
              <li>HTML alapok</li>
              <li>CSS alapok</li>
              <li>A szerver oldali fejlesztés bemutatása</li>
              <li>Django telepítés</li>
              <li>Django App</li>
              <li>A Django Router</li>
              <li>Views és templates</li>
              <li>Static fájlok kezelése</li>
              <li>Django Admin</li>
            </ul>
            <hr />
            <h2><b>Modul 5.</b></h2>            
            <ul>
              <li>Front-end/Back-End architektúra bemutatása</li>
              <li>React környezet ismertetése</li>
              <li>React telepítése</li>
              <li>Ismerkedés a React alapjaival</li>
              <li>React komponensek</li>
              <li>A React router</li>
              <li>Mock adatok a front-end fejlesztésben: json-server</li>
              <li>Arculat kialakítás React környezetben</li>
            </ul>
            <hr />
            <h2><b>Modul 6.</b></h2>            
            <ul>
              <li>Az oldal arculatának fejlesztése</li>
              <li>Animáció alapok: framer-motion</li>
              <li>React Context</li>
              <li>Rest API</li>
            </ul>
            <hr />
            <h2><b>Modul 7.</b></h2>            
            <ul>
              <li>Arculat véglegesítése</li>
              <li>Django-React integráció</li>
              <li>Django-React integráció</li>
              <li>Weboldal előkészítése a webszerver számára</li>
              <li>Oldal publikálása webszerverre</li>
            </ul>

          </h2>
        </div>
        {
            group_list.length > 0&&
            <div className='groups'>
              {group_list.length > 1 && 
              <Fragment>
                <h2 className='center-text'>Ebből az oktatásból több csoport is indul. Válaszd ki a neked megfelelőt.</h2>
                <br />
              </Fragment>
              }
              
              
              {
                group_list.map(group_data => 
                  <GroupCard 
                    key={group_data.name} 
                    name={group_data.name}
                    start_date={group_data.start_date}
                    modules={group_data.modules}
                  />
                )
              }
            </div>
          }  
      </div>
      <hr />
      <h1 className='course-footer'>Hiányoznak az alapok? Nézd meg <Link to="/kezdo">Python Kezdő</Link> oktatásomat!</h1>
    </motion.div>
  )
}
