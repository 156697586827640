import React, { Fragment } from "react";
import Logo from "../images/site_logo.png"
import {motion} from "framer-motion"
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { useState } from "react";


function Menuitems({menu_item}) {
  const location = useLocation()

  return <Fragment>
        <motion.div variants={menu_item} className={location.pathname === "/tanfolyamok"? "menu-item active-page": "menu-item"} whileHover={{scale:1.05}}><Link to="/tanfolyamok">Tanfolyamok</Link></motion.div>
        {/* <motion.div variants={menu_item} className={location.pathname === "/sulirol"? "menu-item active-page": "menu-item"} whileHover={{scale:1.05}}><Link to="/sulirol">A suliról</Link></motion.div> */}
        <motion.div variants={menu_item} className={location.pathname === "/gyik"? "menu-item active-page": "menu-item"} whileHover={{scale:1.05}}><Link to="/gyik">Gyakori kérdések</Link></motion.div>
        <motion.div variants={menu_item} className={location.pathname === "/contact"? "menu-item active-page": "menu-item"} whileHover={{scale:1.05}}><Link to="/contact">Kapcsolat</Link></motion.div>
  </Fragment>
}

export default function Navbar() {
  const [show_menu, set_show_menu] = useState(false)

  const variants = {
    hidden: { opacity:0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
        },
    },
  }

  const menu_item = {
    hidden:{
        opacity: 0,
        x: 50
    },
    show:{
        opacity: 1,
        x: 0,
        transition:{
            duration: 0.4
        }
    }
  }

  const mobile_menu_variants = {
    open: { opacity: 1, x: 0},
    closed: { opacity: 0, y: "-50px", display: "none"},
  }


  return (
    <div className="navbar">
      <Link to="/"><motion.img src={Logo} alt="" className="logo" initial={{opacity:0, y:-50}} animate={{opacity:1, y:0}} whileHover={{scale:1.1}}/></Link>

      <motion.div className="menu" variants={variants} initial="hidden" animate="show">
        <Menuitems/>
      </motion.div>

      <div className="mobile-menu-spacer"/>

      <i className="fa fa-bars mobile-menu-button" aria-hidden="true" onClick={e => set_show_menu(!show_menu)}/>  

      <motion.div className="mobile-menu" onClick={e => set_show_menu(false)} variants={mobile_menu_variants} animate={show_menu ? "open" : "closed"}>
          <Menuitems/>
          <div className="close-box"/>
      </motion.div>


      <motion.div className="social-icons" initial={{opacity:0, y:-50}} animate={{opacity:1, y:0}}>
        <a href="https://www.facebook.com/pythonsuli/" target="_blank" rel="noopener noreferrer"><motion.i className="fab fa-facebook" whileHover={{scale:1.2}}/></a>
        <a href="https://discord.gg/GXqjUehbv7" target="_blank" rel="noopener noreferrer"><motion.i className="fab fa-discord" whileHover={{scale:1.2}}/></a>        
      </motion.div>

    </div>
  );
}