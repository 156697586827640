import React from 'react'

export default function Impresszum() {
  return (
    <div className='center-container'>
        <div>
            <h1>Impresszum</h1>
            <hr />
            <div>
                <p><b>A vállalkozás neve:</b> InkSpiral Kft.</p>
                <p><b>Székhely: </b> 2045 Törökbálint, Téglagyár utca 15.</p>
                <p><b>Adószám:</b> 32193213-2-13</p>
                <p><b>Telefonszám: </b> +36 20 488-3278</p>
                <p><b>E-mail: </b> mail.pythonsuli@gmail.com</p>
                <p><b>Weboldal:</b>  https://www.pythonsuli.hu/</p>
                <p><b>Tárhelyszolgáltató: </b> Linode LLC. Székhely: 249 Arch St Philadelphia, PA, 19106-1915 United States Elérhetőség: (609) 297-5037</p>
                <p><b>Nyilvántartó hatóság:</b>  Nemzeti Adó-és Vámhivatal</p>
            </div>
        </div>
    </div>
  )
}
