import React from 'react'
import {motion} from "framer-motion"
import { useContext } from 'react'
import { CourseContext } from '../contexts/CourseContext'
import { useNavigate } from 'react-router-dom';


export default function GroupCard({name, start_date, modules}) {
  const {course} = useContext(CourseContext)
  const navigate = useNavigate();
  
  const button_clicked = () =>{
    navigate(`/${course}/${name}/application`)
  }

  return <motion.div className='group-card' whileHover={{scale: 1.03}}>
    <h2 className='header'>
      <div>
        <small>Kezdés:</small> <b>{start_date}</b>
      </div>      
      <button onClick={button_clicked}>Jelentkezem</button>
    </h2>
    <hr />
    <div>
      {
        modules.map(module_data => 
        <div key={module_data.name} className="module-container">
          <h3>{module_data.name}</h3>
          <div className='module-dates'>
            {
              module_data.dates.map(session => <p key={session}>{session}</p>)
            }
          </div>
        </div>
        )
      }
    </div>
  </motion.div>
  }