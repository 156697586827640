import React from 'react'
import GYIKImage from "../../images/gyik_01.webp"
import {motion} from "framer-motion"

function QuestionCard({question, content}) {
  return <div className='gyik-card'>
    <h1 className='title'>{question}</h1>
    <h2>{content}</h2>
  </div>
}


export default function GYIK() {
  const isMobile = window.innerWidth <= 500;

  const anim_transition = {duration: 0.5}

  const image_variants = {
    hidden: {
      opacity: 0,
      x: isMobile? 0: -50
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        anim_transition
      }
    }
  }
  const cards_variants = {
    hidden: {
      opacity: 0,
      x: isMobile? 0: 50
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        anim_transition
      }
    }
  }

  return (
    <div className='content-container gyik-container'>
      <motion.img src={GYIKImage} alt="" variants={image_variants} initial="hidden" animate="visible"/>

      <motion.div variants={cards_variants} initial="hidden" animate="visible">
        <QuestionCard 
          question="Hol zajlik a képzés?" 
          content="A Python Suli oktatásai egy erre a célra kialakított online webinar felületen zajlanak. Az oktatásokon úgy vehetsz részt, hogy az oktatás napján a kurzus csoportjában megosztásra kerül a belépéshez használható link. Erre a linkre kattintva indíthatod el a bejelentkezést."
        />
        <QuestionCard 
          question="Készül felvétel az oktatásról?" 
          content="Minden oktatás felvételre kerül amit az oktatást után a felvétel elkészültével megosztunk a csoport számára. Ezek a videók az oktatást követően 7 napig elérhetők letöltésre."
        />
        <QuestionCard 
          question="Kapok oklevelet a kurzus elvégzése után?" 
          content="A Python Suli oktatásai online magánoktatásnak számítanak. Mint ilyen, az iskola nem akkreditált ezért nem adhat papírt az oktatás elvégzése után. A kurzus végén vizsgát sem kell tenned."
        />
        <QuestionCard 
          question="Mennyibe kerül az oktatás?" 
          content="Az oktatások ára óradíjban lett meghatározva amely 2000 Ft/óra. Ez Jelenleg a legolcsóbb oktatások közé sorolja a Python Suli képzéseit. Minden hétvégén 8 órányi oktatást kapsz így alakult ki a végső ár ami 16.000 Ft/hétvége."
        />
        <QuestionCard 
          question="Hogyan lehet jelentkezni?" 
          content={<p>
            A kurzus kiválasztása után a jelentkezési lap kitöltésével lehet jelentkezni. A jelentkezési gombot megtalálod a kurzusok részletes leírása mellett. Mivel az oktatásról számlát kapsz ezért fontos, hogy a jelentkezési lap pontosan legyen kitöltve. 
            A lap beküldése után levélben részletes tájékoztatót kapsz a továbbiakról.
          </p>}
        />
        <QuestionCard 
          question="Meddig tart az oktatás?" 
          content="Minden oktatás 4 órás bontásokban van leadva. A délelőtt 9-től kezdődő oktatások délután 13:00-ig tartanak míg a 14:00-tól kezdődő délutáni kurzusok 18:00-kor fejeződnek be. 
          A 4 órás előadáson van egy 15 perces szünet is."
        />
        <QuestionCard 
          question="Hogy lehet fizetni az oktatásért?" 
          content="A jelentkezés után a kurzusokat fizetheted egyben, illetve ha nem vagy biztos abban, hogy elvégzed a teljes tanfolyamot, részletekben is fizethetsz. Ezt eldöntheted a jelentkezési lapon. 
          A jelentkezésnél kitöltött adatokkal kapsz egy díjbekérő számlát legkésőbb 1 héttel a tanfolyam kezdete előtt, amit a kurzus indulásáig kell kifizetned. Az átutalással kapcsolatos adatok ezen a számlán lesznek feltüntetve."
        />
      </motion.div>
    </div>
  )
}
