import React from 'react'
import {motion} from "framer-motion"
import { Link } from 'react-router-dom'
import KezdoKep from "../../images/01_python_core_tojgli_kigyo_szoveggel.webp"
import HaladoKep from "../../images/full_stack.webp"

function CourseCard({image, title, content, start_x, to, footer}) {
const variants = {
  hidden:{
    opacity:0, 
    x: start_x
  },
  visible:{
    opacity:1, 
    x: 0,
    transition: {
      duration: 0.3
    }
  }
}

  return <motion.div className='course-card' whileHover={{scale: 1.03}} initial="hidden" animate="visible" variants={variants}>
    <Link to={to}>
      <h1 className='title'>{title}</h1>
      <img src={image} alt="" />
      <h2 className='course-card-content'>{content}</h2>
      <hr />
      <h1 className='course-card-footer'>{footer}</h1>
    </Link>
  </motion.div>
}

export default function Courses() {
  const isMobile = window.innerWidth <= 500;
  
  return (
    <div className='content-container course-list'>
      <CourseCard
        start_x={isMobile? 0:-30}
        image={KezdoKep}
        to="/kezdo"
        title="Python Kezdő"
        content="Ismerd meg a világ egyik legkönnyebb programozási nyelvét. Ha soha nem foglalkoztál programozással, vagy van már tapasztalatod de új nyelvet akarsz tanulni, akkor itt a helyed!"
        footer="24 óra | 48.000 Ft (vagy 3 x 16,000 Ft)"
      />

      <CourseCard
        start_x={isMobile? 0:30}
        image={HaladoKep}
        to="/halado"
        title="Python Haladó"
        content="Haladó tanfolyamomon tovább mélyítheted Python tudásod. Ismerd meg velünk hogyan készíthetsz modern alkalmazásokat és weboldalakat Python segítségével."
        footer="56 óra | 112.000 Ft (vagy 7 x 16,000 Ft)"
      />
      </div>
  )
}
