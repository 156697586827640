import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Image1 from "../images/home_image1.png"
import Image2 from "../images/home_image2.png"
import Image3 from "../images/home_image3.png"
import ProfilePic from "../images/ProfilePic.png"
import {motion, useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer";


function Card({title, content, image}) {
  const controls = useAnimation();
  const { ref, inView } = useInView({threshold: 0.5});
  const isMobile = window.innerWidth <= 500;

  const title_variants = isMobile?
  {
    hidden: {opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5
    }
    }
  }
  :
  {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5
    }
    }
  }

  const content_variants = isMobile?
  {
    hidden: {opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5
    }
    }
  }
  :
  {
    hidden: { x: 100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5
    }
    }
  }

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return <div 
    className={`card ${(!title&& "center") || (!content&& "center")}`} 
    style={{backgroundImage: `${image? `url(${image})`:null}`}}
  >
    {
      title && <motion.h1 ref={ref} initial="hidden" animate={controls} variants={title_variants}>{title}</motion.h1>
    }
    {
      (title && content) && <div className="divider"/>
    }
      
    {
      content && <motion.p initial="hidden" animate={controls} variants={content_variants}>{content}</motion.p>
    }
  </div>
}

function Home() {
    const controls = useAnimation();
    const { ref, inView } = useInView({threshold: 0.4});

    const fade_in_variant = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          duration: 0.5
      }
      }
    }

    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);

    return (
      <div className="home">
        <Card
          title="Tanulj programozást korlátok nélkül"
          content="Oktatásaimat online tartom így bárhonnan tanulhatsz, akár munkahelyről, akár otthonról, kényelmesen."
        />

        <Card
          image={Image1}
        />

        <Card
          title="Gyakorlat orientált képzések"
          content="Vége a száraz, unalmas óráknak. Előadásaimon érdekes és hasznos példákon keresztül ismerkedünk a programozás világával."
        />

        <Card
          image={Image2}
        />

        <Card
          title="Légy tagja egy segítőkész csapatnak"
          content="Minden tanfolyamon egy segítőkész hallgatói csoport tagja leszel, ahol nincsenek rossz kérdések. Ha elakadtál a tanulásban, jó helyen vagy!"
        />

        <Card
          image={Image3}
        />

        
        <Card
          title="Kötöttségek nélkül, a saját tempódban"
          content="Nincs vizsga és határidők sem léteznek. Előadásaimat bármikor visszanézheted, hogy a saját tempódban tanulhass."
        />

        <motion.div className="about-me" ref={ref} initial="hidden" animate={controls} variants={fade_in_variant}>
          <div className="profile">
            <img src={ProfilePic} alt="" />
            <h2>Vári Róbert</h2>
            <small>Alapító, mentor</small>
          </div>
          <h1 className="details">
            "Több éves tapasztalatot szereztem fejlesztői és oktatási területen. 
            Ezt a tudást felhasználva igyekszem a legjobb oktatásokat nyújtani azok számára, 
            akik szeretnének programozást tanulni."
          </h1>
        </motion.div>

        <div className="home-footer"><Link to="/tanfolyamok">Nézd meg oktatásaimat</Link> és válaszd a neked legjobbat!</div>
      </div>
    );
  }
  
  export default Home;