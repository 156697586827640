import React from "react";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import PythonKezdo from "./components/pages/PythonKezdo"
import PythonHalado from "./components/pages/PythonHalado"
import GYIK from "./components/pages/GYIK"
import Contact from "./components/pages/Contact"
import Courses from "./components/pages/Courses";
import Impresszum from "./components/pages/Impresszum";
import { CourseProvider } from "./components/contexts/CourseContext";
import Application from "./components/pages/Application";
import ScrollToTop from "./components/micro/ScrollToTop"


function App() {
  return (
    <CourseProvider>
      <BrowserRouter>
        <div className="App">
          <div>
            <Navbar/>
            <ScrollToTop>
              <Routes>
                  <Route path="/kezdo" element={<PythonKezdo/>}/>
                  <Route path="/courses/python-core" element={<PythonKezdo/>}/>
                  <Route path="/halado" element={<PythonHalado/>}/>
                  <Route path="/tanfolyamok" element={<Courses/>}/>
                  <Route path="/gyik" element={<GYIK/>}/>
                  <Route path="/contact" element={<Contact/>}/>
                  <Route path="/impresszum" element={<Impresszum/>}/>
                  <Route path="/:course/:group/application" element={<Application/>}/>
                  <Route path="/" element={<Home/>}/>
              </Routes>
            </ScrollToTop>
          </div>

          <Footer/>      
        </div>
      </BrowserRouter>
    </CourseProvider>
  );
}

export default App;
