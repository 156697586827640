import React, {createContext, useState, useEffect} from "react";
import axios from "axios"
 

export const CourseContext = createContext(true);
const API_URL = process.env.REACT_APP_API_URL

export const CourseProvider = (props) => {
    const [course, set_course] = useState(null)
    const [course_data, set_course_data] = useState(null)
    const [group_list, set_group_list] = useState([])

    const fetch_course = () => {
        axios({
            method: "get",
            url: `${API_URL}/api/courses/${course}/`
        }).then(res => {
            set_course_data(res.data)
            set_group_list(res.data.groups)
        })
    }

    const get_group_dates = (group_name) => {
        let current_group = group_list.filter(item => item.name === group_name)[0]
        return current_group.modules
    }

    const get_group_id = (group_name) => {
        let current_group = group_list.filter(item => item.name === group_name)[0]
        return current_group.id
    }

    const get_group_start_date = (group_name) => {
        let current_group = group_list.filter(item => item.name === group_name)[0]
        return current_group.start_date
    }

    useEffect(() => {
        if(course){
            fetch_course()
        }
    }, [course])

    return (
        <CourseContext.Provider
            value={{
                course: course,
                group_list: group_list,
                course_data: course_data,
                get_group_dates: get_group_dates,
                get_group_id: get_group_id,
                get_group_start_date: get_group_start_date,
                set_course: set_course,
                set_course_data: set_course_data
            }}>
            {props.children}
        </CourseContext.Provider>
    )
};