import React from 'react'
import { useState } from 'react';
import { useContext, useEffect } from 'react';
import {useParams} from "react-router-dom"
import { CourseContext } from '../contexts/CourseContext'
import Checkbox from '../micro/Checkbox';
import ClockLoader from "react-spinners/ClockLoader";
import axios from "axios"
import { motion } from 'framer-motion';

const API_URL = process.env.REACT_APP_API_URL


export default function Application() {
    const {course_data, set_course, get_group_dates, get_group_start_date, get_group_id} = useContext(CourseContext)
    let { course, group } = useParams();
    const [egyben, set_egyben] = useState(true)
    const [aszf, set_aszf] = useState(false)
    const [nyilatkozat, set_nyilatkozat] = useState(false)
    const [application_sent, set_application_sent] = useState(false)
    const [application_accepted, set_application_accepted] = useState(false)
    const [show_company_fields, set_show_company_fields] = useState(false)

    // fields
    const [name, set_name] = useState("")
    const [email, set_email] = useState("")
    const [address, set_address] = useState("")
    const [message, set_message] = useState("")

    // company fields
    const [company_name, set_company_name] = useState("")
    const [company_address, set_company_address] = useState("")
    const [company_id, set_company_id] = useState("")

    // handle errors
    const [name_error, set_name_error] = useState(false)
    const [email_error, set_email_error] = useState(false)
    const [address_error, set_address_error] = useState(false)
    const [aszf_error, set_aszf_error] = useState(false)
    const [nyilatkozat_error, set_nyilatkozat_error] = useState(false)
    const [application_error, set_application_error] = useState(false)
    const [form_error, set_form_error] = useState(false)

    const [company_name_error, set_company_name_error] = useState(false)
    const [company_address_error, set_company_address_error] = useState(false)
    const [company_id_error, set_company_id_error] = useState(false)

    const validateEmail = () => {
        var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(email)
      };

    const check_errors = () => {
        let form_valid = true

        // reset errors
        set_name_error(null)
        set_email_error(null)
        set_address_error(null)
        set_aszf_error(null)
        set_nyilatkozat_error(null)
        set_application_error(null)
        set_form_error(null)
        set_company_name_error(null)
        set_company_address_error(null)
        set_company_id_error(null)

        if(name.length === 0){set_name_error("Név kitöltése kötelező"); form_valid=false; set_form_error(true)}

        if(email.length === 0){
            set_email_error("Email kitöltése kötelező")
            form_valid=false
            set_form_error(true)
        }else if(!validateEmail()){
            set_email_error("Az email formátuma nem megfelelő")
            form_valid=false
            set_form_error(true)
        }

        if(address.length === 0){set_address_error("Cím kitöltése kötelező"); form_valid=false; set_form_error(true)}

        // Company fields
        if(show_company_fields){
            if(company_name.length === 0){set_company_name_error("Cégnév kitöltése kötelező"); form_valid=false; set_form_error(true)}
            if(company_address.length === 0){set_company_address_error("Székhely kitöltése kötelező"); form_valid=false; set_form_error(true)}
            if(company_id.length === 0){set_company_id_error("Adószám kitöltése kötelező"); form_valid=false; set_form_error(true)}
        }

        if(!aszf){set_aszf_error("Fogadd el a szerződési feltételeket"); form_valid=false; set_form_error(true)}
        if(!nyilatkozat){set_nyilatkozat_error("Fogadd el a nyilatkozatot"); form_valid=false; set_form_error(true)}

        return form_valid
    }

    const send_application = async () => {
        const formData = new FormData()
        formData.append("group", get_group_id(group))
        formData.append("name", name)
        formData.append("email", email)
        formData.append("address", address)
        formData.append("payment", egyben? "Egyben": "Részlet")

        if(show_company_fields){
            formData.append("company_name", company_name)
            formData.append("company_address", company_address)
            formData.append("company_id", company_id)
        }

        if(message.length > 0){
            formData.append("message", message)
        }

        try{
            await axios({
                method: "post",
                url: `${API_URL}/api/students/application/`,
                data: formData
            })
            return {success: true, message: "jelentkezés elfogadva"}
        }catch(err){
            console.log("Error", err.response.data.message)
            return {success: false, message: err.response.data.message}
        }
    }

    const button_clicked = async (e) => {
        e.preventDefault()

        const form_valid = check_errors()

        if(!form_valid) return
        
        set_application_sent(true)

        // handle server response
        const server_response = await send_application()
        
        if(!server_response.success){
            set_application_sent(false)
            set_application_error(server_response.message)
        }else{
            window.scrollTo(0, 0)
            set_application_accepted(true)    
        }            
    }

    useEffect(() => {
        set_course(course)
    }, [])
    
    if(!course_data) return
    
    return (
    <div className='content-container'>
        {
            application_accepted?
            <div className='application-form'>
                <h1 className='title center-text'>Köszönöm a jelentkezésed a {course_data.name} tanfolyamra!</h1>
                <hr />
                <h2 className='center-text'>Hamarosan kapsz egy visszajelzést a <b>{email}</b> címre a tanfolyam részleteivel.</h2>
                <h3 className='center-text'>Ha nem érkezik meg az üzenet pár percen belül, akkor valószínű, hogy a <b>spam</b> könyvtárban landolt :( </h3>
                <hr />
                <p>Az alábbiakban leírok neked néhány fontos dolgot a tanfolyammal kapcsolatban.</p>
                <br />
                <p>A választott csoportod azonosítója: <b>{group}</b></p>
                <p>Az első oktatásod időpontja: <b>{get_group_start_date(group)}</b></p>
                <br />
                <p>Az oktatás {get_group_dates(group).length} modulból áll melyek a következő napokon lesznek megtartva:</p>
                <br />
                <div className='group-dates-container'>
                {
                    get_group_dates(group).map(modules => <div className='module'>
                        <h3>{modules.name}</h3>
                        <div>
                            {modules.dates.map(session => 
                                <div className='date'>{session}</div>
                            )}
                        </div>
                        </div>)
                }
                </div>
                <br />
                <h2><b>Kapcsolattartás</b></h2>
                <p>
                    Az képzés idejére a <b><a href="https://slack.com/downloads/windows" target="_blank" rel="noopener noreferrer">Slack</a></b> üzenetküldő alkalmazást használjuk a kapcsolattartásra. <br />
                    Az oktatás előtt pár nappal kapsz egy meghívót amivel csatlakozhatsz a tanfolyam hallgatói csoportjába, ahol az oktatások alatt tartjuk majd a kapcsolatot. 
                    <br /><b>Fontos, hogy ezt a meghívót elfogadd és csatlakozz a csoportba. Ellenkező esetben lemaradhatsz az oktatással kapcsolatos eseményekről.</b> <br />   
                    Ha bármi probléma merül fel a csatlakozással kapcsolatban, vagy egyébb kérdésed van, keress levélben: <b>mail.pythonsuli@gmail.com</b>                
                </p>
                <hr />
                <h2><b>Fizetési információk</b></h2>
                <p>
                    Az oktatás előtt néhány nappal email-ben küldök majd egy díjbekérőt, amit a jelentkezésnél megadott adatok alapján töltök ki. 
                    A fizetéssel kapcsolatos információk szerepelnek majd a levélben és a díjbekérőn.
                </p>
                
                <hr />
                <h2><b>Kilépés a képzésből</b></h2>
                <p>
                    Ez a jelentkezés nem jár semmilyen kötelezettséggel. 
                    Bármi közbejöhet az oktatás előtt, vagy akár közben is, ami miatt úgy gondolod, hogy befejezed a képzést. 
                    A kilépési szándékról írj nekem egy levelet a <b>mail.pythonsuli@gmail.com</b> címre.
                    <br />
                    <b>A képzési díj nem fizetése autómatikus kilépést eredményez.</b>
                </p>
            </div>
            :
            <form action="" className='application-form'>
            <h1 className='title'>{course_data.name}</h1>
            <h1>Jelentkezési lap</h1>
            <hr />

            <div className='form-layout'>
                <div className='fields'>
                    <h2>Kapcsolattartás:</h2>
                    <div className='help-text'>Alább megadott adatok a díjbekérő számlán szerepelnek majd.</div>
                    
                    {name_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {name_error}</div>}
                    <input type="text" placeholder='Név' className={name_error&& `field-error`} value={name} onChange={e => set_name(e.target.value)}/>
                    
                    {email_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {email_error}</div>}
                    <input type="email" placeholder='Email' className={email_error&& `field-error`} value={email} onChange={e => set_email(e.target.value)}/>

                    {address_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {address_error}</div>}
                    <input type="text" placeholder='Levelezési cím' className={address_error&& `field-error`} value={address} onChange={e => set_address(e.target.value)}/>

                    <Checkbox
                        is_checked={show_company_fields}
                        set_checked={() => set_show_company_fields(!show_company_fields)}
                        text={<h2>Céges számlát kérek</h2>}
                    />

                    {show_company_fields&& 
                    <motion.div className='company-fields' initial={{opacity:0, scale: 0.8}} animate={{opacity:1, scale: 1}}>
                        {company_name_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {company_name_error}</div>}
                        <input type="text" placeholder='Cégnév' className={company_name_error&& `field-error`} value={company_name} onChange={e => set_company_name(e.target.value)}/>
                        
                        {company_address_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {company_address_error}</div>}
                        <input type="text" placeholder='Székhely' className={company_address_error&& `field-error`} value={company_address} onChange={e => set_company_address(e.target.value)}/>
                        
                        {company_id_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {company_id_error}</div>}
                        <input type="text" placeholder='Adószám' className={company_id_error&& `field-error`} value={company_id} onChange={e => set_company_id(e.target.value)}/>
                    </motion.div>
                    }

                    <textarea name="" id="" cols="30" rows="10" placeholder='Üzenet a jelentkezés mellé' value={message} onChange={e => set_message(e.target.value)}/>

                    <h2>Fizetési opciók:</h2>
                    <div className='help-text'>
                        <p>Válaszd ki a neked megfelelő fizetési opciót.</p>
                        <p>*Részletekben: Lehetőséged van alkalmanként, részletekben fizetni. Válaszd ezt az opciót ha nem vagy biztos abban, hogy befejezed a tanfolyamot.</p>
                    </div>
                    <div className='payment-options-container'>
                        <Checkbox
                            is_checked={egyben}
                            set_checked={() => set_egyben(true)}
                            text={<h2>Egyben</h2>}
                        />

                        <Checkbox
                            is_checked={!egyben}
                            set_checked={() => set_egyben(false)}
                            text={<h2>*Részletekben</h2>}
                        />
                    </div>
                </div>

                <div className='group-dates-container'>
                    <h2>Kezdés: <b>{get_group_start_date(group)}</b></h2>
                    <h3>Modulok időpontjai:</h3>
                    {
                        get_group_dates(group).map(modules => <div className='module'>
                            <h3>{modules.name}</h3>
                            <div>
                                {modules.dates.map(session => 
                                    <div className='date'>{session}</div>
                                )}
                            </div>
                            </div>)
                    }
                </div>
            </div>

            <hr />

            {aszf_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {aszf_error}</div>}
            <Checkbox
                is_checked={aszf}
                set_checked={set_aszf}
                text={<h2>Megismertem és elfogadom az <a href="https://pythonsuli.hu/static/ASZF-pythonsuli.pdf" target="_blank" rel="noopener noreferrer">Általános Szerződési Feltételeket</a></h2>}
            />

            {nyilatkozat_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {nyilatkozat_error}</div>}
            <Checkbox
                is_checked={nyilatkozat}
                set_checked={set_nyilatkozat}
                text={<h2>Megismertem és elfogadom az <a href="https://pythonsuli.hu/static/Python_Suli_Adatkezelesi.pdf" target="_blank" rel="noopener noreferrer">Adatkezelési tájékoztatót</a></h2>}
            />
            <hr />

            {application_error&& <div className='error right'><i className="fa fa-exclamation-triangle"/> {application_error}</div>}
            {form_error&& <div className='error right'><i className="fa fa-exclamation-triangle"/> Hibákat találtam a jelentkezési lapon. Kérlek ellenőrizd.</div>}

            {
                application_sent? <ClockLoader color={"#fed142"} loading={true} size={50} className="spinner"/>: <button onClick={button_clicked}>Küldés</button>
            }            
            </form>
        }

    </div>
    )
}
