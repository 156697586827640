import React, {useEffect, useContext, Fragment}  from 'react'
import KezdoKep from "../../images/01_python_core_tojgli_kigyo_szoveggel.webp"
import { CourseContext } from '../contexts/CourseContext'
import GroupCard from '../micro/GroupCard'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {motion} from "framer-motion"


export default function PythonKezdo() {
  const {group_list, course_data, set_course, set_course_data} = useContext(CourseContext)
  const location = useLocation()

  useEffect(() => {
    if(location.pathname === "/courses/python-core"){
      set_course("kezdo")
    }else{
      set_course(location.pathname.substring(1))
    }    
  }, [])

  if(!course_data) return

  return (
    <motion.div className='course-details-container' initial={{opacity:0}} animate={{opacity:1}}>
      <div className='course-header'>
        <img src={KezdoKep} alt="" />
        <div>
          <h1 className='title'>{course_data.name}</h1>
          <h3>{course_data.description}</h3>
          <h2>
            <ul>
              <li>Óraszám: <b>{course_data.hours}</b></li>
              <li>Részvételi díj: <b>{course_data.price}</b></li>
              <li>Oktatás jellege: <b>{course_data.type}</b></li>
              <li>Szint: <b>{course_data.level}</b></li>
              <li>Előképzettség: <b>Ez a képzés nem igényel előképzettséget.</b></li>
            </ul>
          </h2>
        </div>
      </div>

      {
        group_list.length === 0&& 
        <div className='no-groups-container'>
          <h1 className='title'>Erre a tanfolyamra elfogytak az időpontok :(</h1>
          <h1>Kövesd <a href="https://www.facebook.com/pythonsuli/" target="_blank" rel="noopener noreferrer">Facebook oldalamat</a> , vagy csatlakozz a <a href="https://discord.gg/GXqjUehbv7" target="_blank" rel="noopener noreferrer">Python Suli Discord</a> szerveréhez ahol időben értesülhetsz arról ha új tanfolyam indul.</h1>
        </div>
      }

      <div className='course-details'>
        <div className='modules-container'>
          <h2>
            <h2><b>Modul I.</b></h2>            
            <ul>
              <li>Adattípusok</li>
              <li>Változók</li>
              <li>Listák</li>
              <li>Kondíciók</li>
              <li>Ciklusok</li>
              <li>Fájlok írása és olvasása</li>
            </ul>
            <hr />

            <h2><b>Modul II.</b></h2>
            <ul>
              <li>Függvények</li>
              <li>Paraméterek és típusaik</li>
              <li>A Scope fogalma</li>
              <li>Modul könyvtárak</li>
              <li>Rekurzió</li>
              <li>Lambda avagy az anonim függvények</li>
              <li>Dekorátor függvények</li>
              <li>Több szálon futó folyamatok</li>
            </ul>
            <hr />

            <h2><b>Modul III.</b></h2>
            <ul>
              <li>Osztályok definiálása</li>
              <li>Attribútumok és metódusok</li>
              <li>A példány</li>
              <li>Setter és Getter metódusok</li>
              <li>A property</li>
              <li>Örökítés</li>
            </ul>
          </h2>
        </div>
          {
            group_list.length > 0&&
            <div className='groups'>
              {group_list.length > 1 && 
                <Fragment>
                  <h2 className='center-text'>Ebből az oktatásból több csoport is indul. Válaszd ki a neked megfelelőt.</h2>
                  <br />
                </Fragment>
              }

              {
                group_list.map(group_data => 
                  <GroupCard 
                    key={group_data.name} 
                    name={group_data.name}
                    start_date={group_data.start_date}
                    modules={group_data.modules}
                  />
                )
              }
            </div>
          }        
      </div>
      <h1 className='course-footer'>Megvannak már az alapok? Nézd meg <Link to="/halado">Python Haladó</Link> oktatásomat!</h1>
    </motion.div>
  )
}
