import React from 'react'
import KapcsolatImage from "../../images/kapcsolat4.webp"
import {motion} from "framer-motion"
import { useState } from 'react'
import axios from "axios"
import ClockLoader from "react-spinners/ClockLoader";

const API_URL = process.env.REACT_APP_API_URL

export default function Contact() {
  const [message_sent, set_message_sent] = useState(false)
  const [waiting_to_server, set_waiting_to_server] = useState(false)
  const [name, set_name] = useState("")
  const [email, set_email] = useState("")
  const [message, set_message] = useState("")

  // handle errors
  const [name_error, set_name_error] = useState(null)
  const [email_error, set_email_error] = useState(null)
  const [message_error, set_message_error] = useState(null)
  const [form_error, set_form_error] = useState(null)

  const validateEmail = () => {
    var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email)
  };

  const check_errors = () => {
    let form_valid = true
    set_waiting_to_server(false)
    set_name_error(null)
    set_email_error(null)
    set_form_error(null)
    set_message_error(null)

    
    if(name.length === 0){set_name_error("Név kitöltése kötelező"); form_valid=false; set_form_error(true)}

    if(email.length === 0){
        set_email_error("Email kitöltése kötelező")
        form_valid=false
        set_form_error(true)
    }else if(!validateEmail()){
        set_email_error("Az email formátuma nem megfelelő")
        form_valid=false
        set_form_error(true)
    }


    if(message.length === 0){set_message_error("Az üzenet mező üres."); form_valid=false; set_form_error(true)}

    return form_valid
  }


  const button_clicked = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append("name", name)
    formData.append("email", email)
    formData.append("message", message)

    const form_valid = check_errors()
    if(!form_valid) return

    set_waiting_to_server(true)

    await axios({
      method: "post",
      url: `${API_URL}/api/contact/`,
      data: formData

    })

    set_message_sent(true)
    set_waiting_to_server(false)
  }

  return (
    <div className='content-container'>
        <motion.div className='cotact-container' initial={{opacity: 0}} animate={{opacity:1}}>
          <img src={KapcsolatImage} alt="" />

          {
            message_sent?
            <div className='message-sent'>Az üzeneted megkaptam. Hamarosan jelentkezem :)</div>
            :
            <div className='contact-form-container'>
              <h1 className='title'>Kérdésed van?</h1>
              <hr />
              <div className='text'>
                <h2>Írj a Python Suli email címére: <b>mail.pythonsuli@gmail.com</b></h2>
                <h3>Vagy</h3>
                <h2>Küldj egy üzenetet az alábbi űrlap segítségével.</h2>
              </div>

              <form>
                {name_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {name_error}</div>}
                <input type="text" placeholder='Név' className={name_error&& `field-error`} value={name} onChange={e => set_name(e.target.value)}/>
                
                {email_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {email_error}</div>}
                <input type="email" placeholder='Email' className={email_error&& `field-error`} value={email} onChange={e => set_email(e.target.value)}/>
                
                {message_error&& <div className='error'><i className="fa fa-exclamation-triangle"/> {message_error}</div>}
                <textarea name="" id="" cols="30" rows="10" placeholder='Üzenet' className={message_error&& `field-error`} value={message} onChange={e => set_message(e.target.value)}/>
                
                {form_error&& <div className='error right'><i className="fa fa-exclamation-triangle"/> Hibákat találtam az űrlapon. Kérlek ellenőrizd.</div>}
                
                
                {
                  waiting_to_server? <ClockLoader color={"#fed142"} loading={true} size={50} className="spinner"/> : <button onClick={button_clicked}>Küldés</button>
                }
                
              </form>
            </div>
          }

        </motion.div>
    </div>
  )
}
